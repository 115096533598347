(function($){
	"use strict";

	// Mean Menu
	$('.mean-menu').meanmenu({
		meanScreenWidth: "991"
	});

	// Header Sticky
	$(window).on('scroll',function() {
		if ($(this).scrollTop() > 120){  
			$('.navbar-area').addClass("is-sticky");
		}
		else{
			$('.navbar-area').removeClass("is-sticky");
		}
	});

	// Last Word Color JS
	$(".main-banner-content h1, .hero-content h1").html(function(){
		var text= $(this).text().trim().split(" ");
		var last = text.pop();
		return text.join(" ") + (text.length > 0 ? " <span>" + last + "</span>" : last);
	});

	// First Word Color JS
	$(".offer-content h2, .offer-time h2").html(function(){
		var text= $(this).text().trim().split(" ");
		var first = text.shift();
		return (text.length > 0 ? "<span>"+ first + "</span> " : first) + text.join(" ");
	});

	// Accordion
	$(".accordion .accordion-item").on("click", function(){
		$(this).toggleClass('active');
		$('.accordion-item').not($(this)).removeClass('active');
	});

	// Video Popup JS
	$('.popup-youtube').magnificPopup({
		disableOn: 320,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	// Home Slides
	$('.home-slides').owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		smartSpeed: 750,
		items: 1,
		navText: [
			"<i class='fas fa-chevron-left'></i>",
			"<i class='fas fa-chevron-right'></i>"
		],
	});
	$(".home-slides").on("translate.owl.carousel", function(){
		$(".hero-content .sub-title").removeClass("animated fadeInUp").css("opacity", "0");
		$(".hero-content h1").removeClass("animated fadeInUp").css("opacity", "0");
		$(".hero-content p").removeClass("animated fadeInUp").css("opacity", "0");
		$(".hero-content .btn").removeClass("animated fadeInUp").css("opacity", "0");
	});
	$(".home-slides").on("translated.owl.carousel", function(){
		$(".hero-content .sub-title").addClass("animated fadeInUp").css("opacity", "1");
		$(".hero-content h1").addClass("animated fadeInUp").css("opacity", "1");
		$(".hero-content p").addClass("animated fadeInUp").css("opacity", "1");
		$(".hero-content .btn").addClass("animated fadeInUp").css("opacity", "1");
	});

	// TV Show Slides
	$('.tv-show-slides').owlCarousel({
		loop: false,
		nav: false,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		mouseDrag: false,
		navText: [
			"<i class='fas fa-chevron-left'></i>",
			"<i class='fas fa-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
	});

	// Blog Slides
	$('.blog-slides').owlCarousel({
		loop: false,
		nav: false,
		dots: true,
		margin: 30,
		autoplayHoverPause: true,
		autoplay: true,
		navText: [
			"<i class='fas fa-chevron-left'></i>",
			"<i class='fas fa-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
	});

	// Feedback Slides
	$('.feedback-slides').owlCarousel({
		loop: true,
		nav: false,
		margin: 30,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		navText: [
			"<i class='fas fa-chevron-left'></i>",
			"<i class='fas fa-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
	});

	// Input Plus & Minus Number JS
	$('.input-counter').each(function() {
		var spinner = jQuery(this),
		input = spinner.find('input[type="text"]'),
		btnUp = spinner.find('.plus-btn'),
		btnDown = spinner.find('.minus-btn'),
		min = input.attr('min'),
		max = input.attr('max');
		
		btnUp.on('click', function() {
			var oldValue = parseFloat(input.val());
			if (oldValue >= max) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue + 1;
			}
			spinner.find("input").val(newVal);
			spinner.find("input").trigger("change");
		});
		btnDown.on('click', function() {
			var oldValue = parseFloat(input.val());
			if (oldValue <= min) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue - 1;
			}
			spinner.find("input").val(newVal);
			spinner.find("input").trigger("change");
		});
	});

	// Tabs
	(function ($) {
		$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs li a').on('click', function (g) {
			var tab = $(this).closest('.tab'), 
			index = $(this).closest('li').index();
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
	})(jQuery);

	// Go to Top
	$(function(){
		// Scroll Event
		$(window).on('scroll', function(){
			var scrolled = $(window).scrollTop();
			if (scrolled > 600) $('.go-top').addClass('active');
			if (scrolled < 600) $('.go-top').removeClass('active');
		});  
		// Click Event
		$('.go-top').on('click', function() {
			$("html, body").animate({ scrollTop: "0" },  500);
		});
	});

    // WOW JS
	$(window).on ('load', function (){
        if ($(".wow").length) { 
            var wow = new WOW({
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       20,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
          });
          wow.init();
        }
    });
    
}(jQuery));